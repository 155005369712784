import { getCheckoutClient } from "@xyo-network/api-checkout-apollo";
import { getRequestContext } from "./getRequestContext";
import gql from "graphql-tag";

export const checkoutClient = getCheckoutClient(
  // 'local',
  'cluster',
  // 'development',
  getRequestContext
);

export const findRebillyCustomer = gql`
query FindRebillyCustomer ($email: String!) {
  customer(email:$email) {
    id
    firstName
    lastName
    lifetimeRevenue {
      amountUsd
    }
    lastPaymentTime
  }
}
`

export const getRebillyCustomer = gql`
query GetRebillyCustomer ($customerId: String!) {
  customer(id:$customerId) {
    id
    firstName
    lastName
    lifetimeRevenue {
      amountUsd
    }
    lastPaymentTime

    paymentInstruments {
      status
      last4
      brand
      expYear
      expYear
    }

    mainSubscription {
      id
      planType
      planId
      status
      inTrial
      billingStatus
      activationTime
      canceledTime
      renewalTime
      startTime

      upcomingInvoice {
        dueTime
        amount
      }
    }

    pastSubscriptions {
      id
      planType
      planId
      status
      inTrial
      billingStatus
      activationTime
      canceledTime
      renewalTime
      startTime
    }
  }
}
`

export const findRebillyCustomerTransactions = gql`
query FindRebillyCustomerTransactions ($customerId: String!) {
  transactions(customerId:$customerId) {
    id
    type
    status
    result
    amount
    createdTime
  }
}
`

export const refundRebillyTransactions = gql`
mutation RefundTransactions($ids: [String!]!) {
  refundTransactions(ids: $ids) {
    id
    type
    status
    result
    amount
    createdTime
  }
}`

export const cancelRebillySubscription = gql`
mutation CancelSubscriptionAdmin(
  $subscriptionId: String!
  $canceledBy: String 
  $reason: String
  $description: String
) {
  cancelSubscriptionAdmin(
    subscriptionId: $subscriptionId
    canceledBy: $canceledBy
    reason: $reason
    description: $description
  ) {
    id
    planType
    planId
    status
    inTrial
    billingStatus
    activationTime
    canceledTime
    renewalTime
    startTime

    upcomingInvoice {
      dueTime
      amount
    }
  }
}`

const AddressFragment = gql`
  fragment Address on Address {
    firstName
    lastName
    country
    address
    emails {
      value
    }
  }
`;

const CustomerFragment = gql`
  fragment Customer on Customer {
    id
    email
    defaultPaymentInstrument {
      paymentCardId
    }
    paymentCount
    primaryAddress {
      ...Address
    }
  }

  ${AddressFragment}
`;

const CardFragment = gql`
  fragment Card on PaymentCard {
    id
    bankCountry
    last4
    expYear
    expMonth
    brand
    status
  }
`;

const UserInfoFragment = gql`
  fragment UserInfo on UserInfo {
    id: ownerUID
    lastMaphash
    xyoRedeemed
    xyoPending
    xyoRedeemPending
    xyoDropped
    xyoCollected
    xyoGifted
    xyoGeomined
    xyoSurveys
    xyoInbrainRevoked
    xyoPickedUp
    xyoGeoclaimed
    xyoHodlRewards
    xyoBossBalance
    xyoBossGifted
    xyoExpired
    xyoRestored
    xyoSweepstakes
    xyoEnabled
    hodlLastCollectedYYYYMMDD
    recordVersion
    remineMarker
    recentCount
    color
    referrer
    checkInDate
    created
    appVersion
    nfcScanCount
    referrerUid
    addedToDrip
    mineCountNoSentinel
    ibeacons
    lastMinedWithSentinelBle
    remineCount
    subscriptionInfo
    osDevMode
    ownerUID
    osVersion
    mineCount
    recentMarker
    mineCountSentinel
    direction
    activeSubscription
    activeSubscriptionLock
    subActive
    suspended
    ip
    lastMinedYYYYMMDD
    verifiedAddressesTotal
    sentinels
    random
    liteOk
    masterOk
    reducedRewards
    allowOffers
    kycComplete
    username
    ethAddress
    nftAddress
    lastKnownAddress
    coinbaseEmail
    shipStreetAddress
    webDropsAllowed
    webDropsAmountMax
    webDropsQuantityMax
    lastMined
    ringDate
    firstMaphash
    profileImageExists
    profileImageUpdated
    subOrder
    customerId
    lastSync
    ssid
    os
    deviceId
    deviceIds
    advertisingId
    deviceName
    speed
    appsflyerId
    didReceiveReturningUserGift
    driveAnalysisQualified
    driveAnalysisRegistered
    driveAnalysisQualified
    driveAnalysisCompleted
    driveAnalysisRewarded
    driveAnalysisReward
    didReceiveZendrizeDrop
    driveAnalysisQualifiedDate
    driveAnalysisRegisteredDate
    driveAnalysisActiveSubscription
    phoneNumber
    countryCode

    userDeleted
    userDeletedTime
    userDeletedSource

    userQualityScore
    userQualityScoreUpdatedBy
    userQualityScoreUpdatedDate
    profileMultiplier
  }
`;

const RebillyTransactionFragment = gql`
  fragment RebillyTransaction on Transaction {
    id
    amount
    status
    result
    createdTime
    gateway
    currency
  }
`;

const AchievementsCollectedFragment = gql`
  fragment AchievementCollected on AchievementCollected {
    id
    achievement
    info {
      id
      achievement
      nftId
      type
      rank
      group
      title
      description
      image
      icon
      imageUrl
      iconUrl
    }
    mint {
      id
      reference
      state
      ethAddress
      transactionHash
      requestedTime
      completedTime
      mintError
      initialHash
    }
    date
  }
`;

export const UserAchievementsCollected = gql`
  query UserAchievementsCollected($uids: [String!]) {
    accounts(uids: $uids) {
      userInfo {
        ownerUID
        achievementsCollected {
          ...AchievementCollected
        }
      }
    }
  }

  ${AchievementsCollectedFragment}
`;

const InvoiceFragment = gql`
  fragment Invoice on Invoice {
    id
    dueTime
    issuedTime
    abandonedTime
    voidedTime
    paidTime
    currency
    invoiceNumber
    customerId
    websiteId
    organizationId
    subscriptionId
    billingAddress {
      ...Address
    }
    deliveryAddress {
      ...Address
    }
    amount
    currency
    subtotalAmount
    shippingAmount
    poNumber
    notes
    items {
      id
      type
      description
      unitPrice
    }
    discountAmount
    discounts
    taxAmount
    taxes
    transactions {
      ...RebillyTransaction
    }
    autopayScheduledTime
    autopayRetryNumber
    status
    delinquentCollectionPeriod
    collectionPeriod
    createdTime
    updatedTime
  }

  ${AddressFragment}
  ${RebillyTransactionFragment}
`

const RebillySubscriptionFragment = gql`
  fragment RebillySubscription on RebillySubscription {
    id
    planType
    planId
    status
    billingStatus
    activationTime
    canceledTime
    renewalTime
    startTime
    upcomingInvoice {
      ...Invoice
    }
    recentInvoice {
      ...Invoice
    }
    initialInvoice {
      ...Invoice
    }
  }

  ${RebillyTransactionFragment}
  ${InvoiceFragment}
`;

export const AccountsQuery = gql`
  query Accounts($uids: [String!], $emails: [String!]) {
    accounts(uids: $uids, emails: $emails) {
      customer {
        ...Customer
        mainSubscription {
          ...RebillySubscription
        }
        subscriptions {
          ...RebillySubscription
        }
        paymentCards {
          ...Card
        }
      }
      userInfo {
        ...UserInfo
        achievementsCollected {
          ...AchievementCollected
        }
      }
      uid
      email
      customerId
    }
  }

  ${CardFragment}
  ${CustomerFragment}
  ${UserInfoFragment}
  ${RebillySubscriptionFragment}
  ${RebillyTransactionFragment}
  ${AchievementsCollectedFragment}
`;

export const findAccounts = gql`
  query FindAccounts($search: String!) {
    findAccounts(search: $search) {
      customer {
        ...Customer
        mainSubscription {
          ...RebillySubscription
        }
        subscriptions {
          ...RebillySubscription
        }
        paymentCards {
          ...Card
        }
      }
      userInfo {
        ...UserInfo
        achievementsCollected {
          ...AchievementCollected
        }
      }
      uid
      email
      customerId
    }
  }

  ${CardFragment}
  ${CustomerFragment}
  ${UserInfoFragment}
  ${RebillySubscriptionFragment}
  ${RebillyTransactionFragment}
  ${AchievementsCollectedFragment}
`;

export const remintAchievement = gql`
mutation RemintAchievement ($reference: String!, $address: String!, $note: String) {
  remintAchievement (reference: $reference, address: $address, note: $note) {
    id
    reference
    state
    ethAddress
    transactionHash
    requestedTime
    completedTime
    mintError
    initialHash
  }
}
`

export const forceMintAchievement = gql`
mutation ForceMintAchievement ($achievement: String!, $uid: String!, $address: String!, $note: String) {
  forceMintAchievement (achievement: $achievement, uid: $uid, address: $address, note: $note) {
    id
    reference
    state
    ethAddress
    transactionHash
    requestedTime
    completedTime
    mintError
    initialHash
  }
}
`

export const processedRefundRequest = gql`
    mutation ProcessedRefundRequest($id: String!) {
  processedRefundRequest(id: $id) {
    ...RefundRequest
  }
}
    
    fragment RefundRequest on RefundRequest {
  id
  url
  uid
  email
  photoURL
  date
  expireAt
  refunded
  refunding
  accepted
  active
  error
  startTime
  activeSubscription
  iosSub
  iosErr
  androidSub
  androidErr
  rebillySub {
    subscriptionId
    transactions {
      createdTime
      transactionId
      amount
    }
  }
  rebillyErr
  eligibleMessage
  eligibleMonths
  eligibleAmount
  collectedAmount
  collectedAmountRange
  collectedCount
  droppedAmount
  dropCount
  redeemCount
  xyoCollected
  resetXyoCollectedAmount
}
`


export const GET_ACHIEVEMENTS = gql`
  query GetAchievements {
    achievements {
      id
      achievement
      title
      imageUrl
      iconUrl
    }
  }
`

export const ADD_ACHIEVEMENT = gql`
  mutation AddAchievement($achievement: String!, $uid: String!, $note: String) {
    addAchievement(achievement: $achievement, uid: $uid, note: $note) {
      id
      achievement
    }
  }
`
