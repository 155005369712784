import './firebase/firebase'
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Admin, defaultI18nProvider, useStoreContext } from "react-admin";
import { HashRouter } from "react-router-dom";
import { AdminAuthProvider } from "./firebase/context";
import store, { history } from "./firebase/store";
import {
  AdminUI,
  AdminContext,
  AdminContextProps,
  AdminUIProps,
} from 'ra-ui-materialui';
import * as serviceWorker from "./serviceWorker";
import Splash from "./components/Splash";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ErrorBoundary } from "./components/Error/Boundary";
import { SnackbarProvider } from "notistack";
import { InvertableThemeProvider } from "./theme";
import App from "./App";
import "./index.css";
import { GeneratedImagesProvider } from './routes/Usernames/hooks/useGenerateImage';
import dataProvider from './firebase/dataProvider';

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { useTheme } from '@material-ui/core';

// if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
// }

const reload = () => {
  window.location.reload(true)
}

const Main = () => {
  const store = useStoreContext()
  const theme = useTheme()
  console.log("STORE", store)
  return <AdminContext dataProvider={dataProvider} i18nProvider={defaultI18nProvider} theme={theme}>
   <SnackbarProvider>
      {/* <TranslationProvider> */}
          <AdminAuthProvider>
            <GeneratedImagesProvider>
              <CssBaseline />
              <App />
            </GeneratedImagesProvider>
          </AdminAuthProvider>
      {/* </TranslationProvider> */}
    </SnackbarProvider>
    </AdminContext>
}

ReactDOM.render(
  <InvertableThemeProvider>
    <Suspense fallback={<Splash>Loading...</Splash>}>
      <ErrorBoundary
        fallback={(error) => (
          <Splash>
            <div>
              {error.message}{" "}
              <button className="btn btn-outline-light ml-2" onClick={reload}>
                Reload
              </button>
            </div>
          </Splash>
        )}
      >
        <HashRouter>
          <Main />
        </HashRouter>
      </ErrorBoundary>
    </Suspense>
  </InvertableThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
