import React, { Fragment, useEffect, useState, Suspense } from "react";
import { Routes, Route, NavLink as ReactNavLink } from "react-router-dom";
import { List, ListItem, Collapse, Box, Typography, MenuItem } from "@material-ui/core"
import { Admin, Resource } from "react-admin";
import { RequireCredentials } from "../auth/Required";
import {
  useFirebaseUser,
  useFirebaseUserCredentials,
} from "../firebase/context";
import Navbar from "../components/Navbar";
import Snackables, { SnackbarProvider } from "../components/Snackables";
import Splash from "../components/Splash";
import get from "lodash/get";
import { ArrowDropDown } from "@material-ui/icons";
import cx from "classnames";

const Home = React.lazy(() => import("./Home"));
const Users = React.lazy(() => import("./Users"));
const Admins = React.lazy(() => import("./Admins"));
const User = React.lazy(() => import("./User"));
const Customer = React.lazy(() => import("./Customer"));
const Search = React.lazy(() => import("./Search"));
const Login = React.lazy(() => import("./Login"));
const Accounts = React.lazy(() => import("./Accounts"));
const AccountLogs = React.lazy(() => import("./AccountLogs"));
const Maphashes = React.lazy(() => import("./Maphashes"));
const NotFound = React.lazy(() => import("./NotFound"));
const NonAdmin = React.lazy(() => import("./NonAdmin"));
const Settings = React.lazy(() => import("./Settings"));
const Beacons = React.lazy(() => import("./Beacons"));
const Beacon = React.lazy(() => import("./Beacon"));
const Subscriptions = React.lazy(() => import("./Subscriptions"));
const RedeemHistory = React.lazy(() => import("./RedeemHistory"));
const RedeemBatches = React.lazy(() => import("./RedeemBatches"));
const RedeemReview = React.lazy(() => import("./RedeemReview"));
const RedeemItems = React.lazy(() => import("./RedeemItems"));
const RefundHistory = React.lazy(() => import("./RefundHistory"));
const Shipstation = React.lazy(() => import("./Shipstation"));
const Order = React.lazy(() => import("./Shipstation/Order"));
const Inmarket = React.lazy(() => import("./Inmarket"));
const InmarketMap = React.lazy(() => import("./Inmarket/map"));
const Foursquare = React.lazy(() => import("./Foursquare"));
const FoursquareMap = React.lazy(() => import("./Foursquare/map"));
const Sweepstakes = React.lazy(() => import("./Sweepstakes"));
const SystemDrops = React.lazy(() => import("./SystemDrops"));
const ReportedUsers = React.lazy(() => import("./Reported"));
const EmailChange = React.lazy(() => import("./EmailChange"));
const DriverAnalysis = React.lazy(() => import("./DriverAnalysis"));
const ThirdPartyRewards = React.lazy(() => import("./ThirdPartyRewards"));
const PartnerRewards = React.lazy(() => import("./PartnerRewards"));
const AppAdsEditor = React.lazy(() => import("./AppAdsEditor"));
const BusinessEntries = React.lazy(() => import("./BusinessEntries"));
const Analytics = React.lazy(() => import("./Analytics"));
const Zendesk = React.lazy(() => import("./Zendesk"));
const ShareableStats = React.lazy(() => import("./ShareableStats"));
const Shopping = React.lazy(() => import("./Shopping"));
const Booking = React.lazy(() => import("./Booking"));
const Learning = React.lazy(() => import("./Learning"));
const Leaderboards = React.lazy(() => import("./Leaderboards"));
const Heatmap = React.lazy(() => import("./Heatmap"));
const SpecialOffers = React.lazy(() => import("./SpecialOffers"));
const LimitedReleases = React.lazy(() => import("./LimitedReleases"));
const RemoteAlerts = React.lazy(() => import("./RemoteAlerts"));
const Web3Configs = React.lazy(() => import("./Web3Configs"));
const UnrewardedTasks = React.lazy(() => import("./UnrewardedTasks"));
const UsernamesList = React.lazy(() => import("./Usernames/routes/List"));
const UsernameDetail = React.lazy(() => import("./Usernames/routes/UsernameDetail"));
const UsernameWordLists = React.lazy(() => import("./Usernames/routes/WordList"));
const ProfilesList = React.lazy(() => import("./Profiles/routes/List"));
const ProfileDetail = React.lazy(() => import("./Profiles/routes/ProfileDetail"));

const NavLink = ({ className, ...props }) => {
  return <MenuItem style={{ paddingLeft: 32 }} component={ReactNavLink} {...props}></MenuItem>
}

const Dropdown = ({ children, open, toggle, name }) => {
  return (
    <>
    <MenuItem onClick={toggle(name)}>
      {name}
      <Box style={{ flex: 1 }} />
      <ArrowDropDown className={cx('dropdown', { open: open === name })} />
    </MenuItem>
    <Collapse in={open === name}>
      <List>
        {children}
      </List>
    </Collapse>
    </>
  );
};

const FullNav = () => {
  const [open, setOpen] = useState("");
  const toggle = (v) => () => setOpen((prev) => (prev === v ? "" : v));
  const close = (ev) => {
    ev.stopPropagation();
    // setOpen("");
  }
  return (
    <List>
      <Dropdown name="Analytics" open={open} toggle={toggle}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/"
          exact
          onClick={close}
        >
          Dashboard
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/shareable-stats"
          onClick={close}
        >
          Shareable Stats
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/live-view"
          exact
          onClick={close}
        >
          Live View
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/beacons"
          exact
          onClick={close}
        >
          Beacons
        </NavLink>
      </Dropdown>
      <Dropdown name="Partners" open={open} toggle={toggle}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/shipstation/orders"
          onClick={close}
        >
          Ship Station
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/inmarket"
          onClick={close}
        >
          Inmarket
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/partner-rewards"
          onClick={close}
        >
          Rewards
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/3rd-party"
          onClick={close}
        >
          3rd Party
        </NavLink>
      </Dropdown>
      <Dropdown name="Customers" open={open} toggle={toggle}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/accounts"
          onClick={close}
        >
          Accounts
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/refund-history"
          onClick={close}
        >
          Refunds
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/redeem-history"
          onClick={close}
        >
          Redeems
        </NavLink>
        <RequireCredentials required={["coin_unrewarded_tasks_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/unrewarded-tasks"
            onClick={close}
          >
            Unrewarded Tasks
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_subscriptions_stats"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/subscriptions"
            onClick={close}
          >
            Subscriptions
          </NavLink>
        </RequireCredentials>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/zendesk"
          onClick={close}
        >
          Zendesk
        </NavLink>
      </Dropdown>
      <Dropdown name="System" open={open} toggle={toggle}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/analytics"
          onClick={close}
        >
          Analytics
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/drops"
          onClick={close}
        >
          Daily Drops
        </NavLink>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/redeem-items"
          onClick={close}
        >
          Redeem Items
        </NavLink>
        <RequireCredentials required={["coin_sweepstakes_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/sweepstakes"
            onClick={close}
          >
            Sweepstakes
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_shopping_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/shopping"
            onClick={close}
          >
            Shopping
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_booking_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/booking"
            onClick={close}
          >
            Booking
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_learning_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/learning"
            onClick={close}
          >
            Learning
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_remote_alerts_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/remote-alerts"
            onClick={close}
          >
            Remote Alerts
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_web3_configs_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/web3-configs"
            onClick={close}
          >
            Web3 Configs
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_special_offers_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/special-offers"
            onClick={close}
          >
            Special Offers
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_limited_releases_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/limited-releases/admin"
            onClick={close}
          >
            Limited Releases
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_users_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/users-reported"
            onClick={close}
          >
            Reported Users
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_users_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/users-email-changed"
            onClick={close}
          >
            Email Changed
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_users_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/driver-qualified"
            onClick={close}
          >
            Driver Qualified
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_users_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/business-entries"
            onClick={close}
          >
            Business Entries
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_app_ads_txt"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/appads"
            onClick={close}
          >
            App Ads Editor
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_usernames_write"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/usernames"
            onClick={close}
          >
            Usernames
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_profiles_write"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/profiles"
            onClick={close}
          >
            Profiles
          </NavLink>
        </RequireCredentials>
      </Dropdown>
    </List>
  );
};

export const navbars = {
  full: <FullNav />,
  fulfillment_europe: (
    <List>
      <NavLink className="nav-item" activeClassName="active" to="/shipstation/orders">
        <span className="nav-link">Sendcloud</span>
      </NavLink>
      <RequireCredentials required={["coin_subscriptions_stats"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/subscriptions"
        >
          <span className="nav-link">Subscriptions</span>
        </NavLink>
      </RequireCredentials>
      <NavLink
          className="nav-item"
          activeClassName="active"
          to="/shareable-stats"
        >
          Shareable Stats
        </NavLink>
        <RequireCredentials required={["coin_usernames_write"]}>
          <NavLink
            className="nav-item"
            activeClassName="active"
            to="/usernames"
          >
            <span className="nav-link">Usernames</span>
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_profiles_write"]}>
          <NavLink
            className="nav-item"
            activeClassName="active"
            to="/profiles"
          >
            <span className="nav-link">Profiles</span>
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_remote_alerts_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/remote-alerts"
          >
            Remote Alerts
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_web3_configs_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/web3-configs"
          >
            Web3 Configs
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_special_offers_read"]}>
          <NavLink
            className="nav-item"
            activeClassName="active"
            to="/special-offers"
          >
            <span className="nav-link">Special Offers</span>
          </NavLink>
        </RequireCredentials>
        <RequireCredentials required={["coin_limited_releases_read"]}>
          <NavLink
            className="nav-item"
            activeClassName="active"
            to="/limited-releases/admin"
          >
            <span className="nav-link">Limited Releases</span>
          </NavLink>
        </RequireCredentials>
    </List>
  ),
  fulfillment: (
    <List>
      <NavLink
        className="nav-item"
        activeClassName="active"
        to="/redeem-history"
      >
        <span className="nav-link">Redeem History</span>
      </NavLink>
      <NavLink
        className="nav-item"
        activeClassName="active"
        to="/refund-history"
      >
        <span className="nav-link">Refund History</span>
      </NavLink>
      <NavLink className="nav-item" activeClassName="active" to="/shipstation/orders">
        <span className="nav-link">Ship Station</span>
      </NavLink>
      <RequireCredentials required={["coin_subscriptions_stats"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/subscriptions"
        >
          <span className="nav-link">Subscriptions</span>
        </NavLink>
      </RequireCredentials>
      <NavLink
        className="nav-item"
        activeClassName="active"
        to="/shareable-stats"
      >
        Shareable Stats
      </NavLink>
      <RequireCredentials required={["coin_usernames_write"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/usernames"
        >
          <span className="nav-link">Usernames</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_profiles_write"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/profiles"
        >
          <span className="nav-link">Profiles</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_remote_alerts_read"]}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/remote-alerts"
        >
          Remote Alerts
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_web3_configs_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/web3-configs"
          >
            Web3 Configs
          </NavLink>
        </RequireCredentials>
      <RequireCredentials required={["coin_special_offers_read"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/special-offers"
        >
          <span className="nav-link">Special Offers</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_limited_releases_read"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/limited-releases/admin"
        >
          <span className="nav-link">Limited Releases</span>
        </NavLink>
      </RequireCredentials>
    </List>
  ),
  customer_obsession: (
    <List>
      <RequireCredentials required={["coin_subscriptions_stats"]}>
        {/* <NavLink className='nav-item' activeClassName='active' to='/shipstation'>
        <span className='nav-link'>Ship Station</span>
      </NavLink> */}
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/subscriptions"
        >
          <span className="nav-link">Subscriptions</span>
        </NavLink>
      </RequireCredentials>
      <NavLink
        className="nav-item"
        activeClassName="active"
        to="/shareable-stats"
      >
        Shareable Stats
      </NavLink>
      <RequireCredentials required={["coin_usernames_write"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/usernames"
        >
          <span className="nav-link">Usernames</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_profiles_write"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/profiles"
        >
          <span className="nav-link">Profiles</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_remote_alerts_read"]}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/remote-alerts"
        >
          Remote Alerts
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_web3_configs_read"]}>
          <NavLink
            className="dropdown-item"
            activeClassName="active"
            to="/web3-configs"
          >
            Web3 Configs
          </NavLink>
        </RequireCredentials>
      <RequireCredentials required={["coin_special_offers_read"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/special-offers"
        >
          <span className="nav-link">Special Offers</span>
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_unrewarded_tasks_read"]}>
        <NavLink
          className="dropdown-item"
          activeClassName="active"
          to="/unrewarded-tasks"
        >
          Unrewarded Tasks
        </NavLink>
      </RequireCredentials>
      <RequireCredentials required={["coin_limited_releases_read"]}>
        <NavLink
          className="nav-item"
          activeClassName="active"
          to="/limited-releases/admin"
        >
          <span className="nav-link">Limited Releases</span>
        </NavLink>
      </RequireCredentials>
    </List>
  ),
};

export const routers = {
  full: (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/analytics" element={<Analytics />} exact />
      <Route path="/search" element={<Search />} exact />
      <Route path="/users" element={<Users />} exact />
      <Route path="/admins" element={<Admins />} exact />
      <Route path="/user/:id" element={<User />} exact />
      <Route path="/usernames" element={<UsernamesList />} exact />
      <Route path="/username/wordlists" element={<UsernameWordLists />} exact />
      <Route path="/username/:username" element={<UsernameDetail />} exact />
      <Route path="/profiles" element={<ProfilesList />} exact />
      <Route path="/profile/:username" element={<ProfileDetail />} exact />
      <Route path="/customer/:id" element={<Customer />} exact />
      <Route path="/logs" element={<AccountLogs />} exact />
      <Route path="/accounts" element={<Accounts />} exact />
      <Route path="/live-view" element={<Maphashes />} exact />
      <Route path="/beacons" element={<Beacons />} exact />
      <Route path="/beacon/:id" element={<Beacon />} exact />
      <Route path="/redeem-items" element={<RedeemItems />} exact />
      <Route path="/redeem-history" element={<RedeemHistory />} exact />
      <Route path="/redeem-review" element={<RedeemReview />} exact />
      <Route path="/redeem-batches" element={<RedeemBatches />} exact />
      <Route path="/refund-history" exact element={<RefundHistory />} />
      <Route path="/settings" element={<Settings />} exact />
      <Route path="/nonadmin" element={<NonAdmin />} exact />
      <Route path="/subscriptions" element={<Subscriptions />} exact />
      <Route path="/shipstation/order/:id" element={<Order />} exact />
      <Route path="/shipstation/*" element={<Shipstation />} />
      <Route path="/inmarket" exact element={<Inmarket />} />
      <Route path="/inmarket/map" element={<InmarketMap />} />
      <Route path="/sweepstakes" exact element={<Sweepstakes />} />
      <Route path="/shopping" exact element={<Shopping />} />
      <Route path="/booking" exact element={<Booking />} />
      <Route path="/learning" exact element={<Learning />} />
      <Route path="/special-offers" exact element={<SpecialOffers />} />
      <Route path="/limited-releases/*" exact element={<LimitedReleases />} />
      <Route path="/remote-alerts" exact element={<RemoteAlerts />} />
      <Route path="/web3-configs" exact element={<Web3Configs />} />
      <Route path="/unrewarded-tasks" exact element={<UnrewardedTasks />} />
      <Route path="/shareable-stats" exact element={<ShareableStats />} />
      <Route path="/users-reported" exact element={<ReportedUsers />} />
      <Route path="/business-entries" exact element={<BusinessEntries />} />
      <Route path="/users-email-changed" exact element={<EmailChange />} />
      <Route path="/driver-qualified" exact element={<DriverAnalysis />} />
      <Route path="/appads" exact element={<AppAdsEditor />} />
      <Route path="/drops" exact element={<SystemDrops />} />
      <Route path="/foursquare/*" element={<Foursquare />} />
      <Route
        path="foursquare-map/*"
        element={<FoursquareMap />}
      />
      <Route path="/3rd-party" exact element={<ThirdPartyRewards />} />
      <Route path="/partner-rewards" exact element={<PartnerRewards />} />
      <Route path="/zendesk" element={<Zendesk />} exact />
      <Route path="/heatmap" element={<Heatmap />} exact />
      <Route path="/leaderboards" element={<Leaderboards />} exact />
      <Route path="*" element={<NotFound />} />
    </Routes>
  ),
  fulfillment_europe: (
    <Routes>
      <Route path="/" element={<Search />} exact />
      <Route path="/search" element={<Search />} exact />
      <Route path="/user/:id" element={<User />} exact />
      <Route path="/customer/:id" element={<Customer />} exact />
      <Route path="/settings" element={<Settings />} exact />
      <Route path="/nonadmin" element={<NonAdmin />} exact />
      <Route path="/shipstation/order/:id" element={<Order />} exact />
      <Route path="/shipstation/*" element={<Shipstation />} />
      <Route path="/zendesk" element={<Zendesk />} exact />
      <Route path="/shareable-stats" exact element={<ShareableStats />} />
      <Route path="/special-offers" exact element={<SpecialOffers />} />
      <Route path="/limited-releases/*" exact element={<LimitedReleases />} />
      <Route path="/remote-alerts" exact element={<RemoteAlerts />} />
      <Route path="/web3-configs" exact element={<Web3Configs />} />
      <Route path="/usernames" element={<UsernamesList />} exact />
      <Route path="/profiles" element={<ProfilesList />} exact />
      <Route path="/heatmap" element={<Heatmap />} exact />
      <Route path="/leaderboards" element={<Leaderboards />} exact />
      <Route path="*" element={<NotFound />} />
    </Routes>
  ),
  fulfillment: (
    <Routes>
      <Route path="/" element={<Search />} exact />
      <Route path="/search" element={<Search />} exact />
      <Route path="/user/:id" element={<User />} exact />
      <Route path="/customer/:id" element={<Customer />} exact />
      <Route path="/settings" element={<Settings />} exact />
      <Route path="/nonadmin" element={<NonAdmin />} exact />
      <Route path="/subscriptions" element={<Subscriptions />} exact />
      <Route path="/shipstation/order/:id" element={<Order />} exact />
      <Route path="/shipstation/*" element={<Shipstation />} />
      <Route path="/redeem-history" element={<RedeemHistory />} exact />
      <Route path="/redeem-review" element={<RedeemReview />} exact />
      <Route path="/redeem-batches" element={<RedeemBatches />} exact />
      <Route path="/refund-history" element={<RefundHistory />} exact />
      <Route path="/logs" element={<AccountLogs />} exact />
      <Route path="/zendesk" element={<Zendesk />} exact />
      <Route path="/shareable-stats" exact element={<ShareableStats />} />
      <Route path="/special-offers" exact element={<SpecialOffers />} />
      <Route path="/limited-releases/*" exact element={<LimitedReleases />} />
      <Route path="/remote-alerts" exact element={<RemoteAlerts />} />
      <Route path="/web3-configs" exact element={<Web3Configs />} />
      <Route path="/usernames" element={<UsernamesList />} exact />
      <Route path="/profiles" element={<UsernamesList />} exact />
      <Route path="/heatmap" element={<Heatmap />} exact />
      <Route path="/leaderboards" element={<Leaderboards />} exact />
      <Route path="*" element={<NotFound />} />
    </Routes>
  ),
  customer_obsession: (
    <Routes>
      <Route path="/" element={<Search />} exact />
      <Route path="/search" element={<Search />} exact />
      <Route path="/user/:id" element={<User />} exact />
      <Route path="/customer/:id" element={<Customer />} exact />
      <Route path="/settings" element={<Settings />} exact />
      <Route path="/nonadmin" element={<NonAdmin />} exact />
      <Route path="/subscriptions" element={<Subscriptions />} exact />
      <Route path="/logs" element={<AccountLogs />} exact />
      <Route path="/sweepstakes" exact element={<Sweepstakes />} />
      <Route path="/zendesk" element={<Zendesk />} exact />
      <Route path="/shareable-stats" exact element={<ShareableStats />} />
      <Route path="/special-offers" exact element={<SpecialOffers />} />
      <Route path="/limited-releases/*" exact element={<LimitedReleases />} />
      <Route path="/remote-alerts" exact element={<RemoteAlerts />} />
      <Route path="/web3-configs" exact element={<Web3Configs />} />
      <Route path="/unrewarded-tasks" exact element={<UnrewardedTasks />} />
      <Route path="/usernames" element={<UsernamesList />} exact />
      <Route path="/profiles" element={<ProfilesList />} exact />
      <Route path="/heatmap" element={<Heatmap />} exact />
      <Route path="/leaderboards" element={<Leaderboards />} exact />
      {/* <Route path='/shipstation/order/:id' element={<Order />} exact />
    <Route path='/shipstation' element={<Shipstation />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  ),
};

const UnauthenticatedRouter = () => {
  return (
    <Routes>
      <Route path="/heatmap" element={<Heatmap />} exact />
      <Route path="/leaderboards" element={<Leaderboards />} exact />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default () => {
  const user = useFirebaseUser();
  const credentials = useFirebaseUserCredentials();
  const routerType = get(credentials, "router");
  const navLinks = navbars[routerType];
  const router = routers[routerType];
  return (
    <SnackbarProvider>
      {user ? router ? <Navbar navLinks={navLinks}>
        <Resource name="coin_users" context="registration" />
        <Resource name="refund_history" context="registration" />
        <Resource name="redeem_history" context="registration" />
        <Resource name="redeem_options" context="registration" />
        <Resource name="ship_station_carriers" context="registration" />
        <Resource name="ship_station_orders" context="registration" />
        <Resource name="ship_station_orders_by_tag" context="registration" />
        <Resource name="ship_station_shipments" context="registration" />
        <Resource name="ship_station_fulfillments" context="registration" />
        <Resource name="ship_station_products" context="registration" />
        <Resource name="ship_station_tags" context="registration" />
        <Resource name="ship_station_stores" context="registration" />
        <Suspense fallback={<Splash loading={true}>Loading...</Splash>}>
          {router}
        </Suspense>
      </Navbar> : <UnauthenticatedRouter /> : <UnauthenticatedRouter />}
      <Snackables />
    </SnackbarProvider>
  );
};
