import React, { useState, Suspense } from 'react'
import { useFirebaseAuth, useFirebaseUser, useFirebaseUserCredentials } from '../firebase/context'
import useCreateMockWithdrawal from '../components/CreateMockWithdrawal'
import Menu from '@material-ui/core/Menu'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import ButtonBase from '@material-ui/core/ButtonBase'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { useContractBalance } from '../utils/web3'
import { Link } from 'react-router-dom'
import { readableNumber } from '../utils/number';

const defaultPhotoUrl = require('./xyo.svg')

const XyoBalance = () => {
  const balance = useContractBalance(process.env.REACT_APP_TOKEN_ADDRESS)
  if (!balance) return ''
  return <div className='dropdown-item'>{readableNumber(balance)} XYO</div>
}

export default ({ }) => {  
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const createMockWithdrawal = useCreateMockWithdrawal()
  const auth = useFirebaseAuth()
  const user = useFirebaseUser()
  const creds = useFirebaseUserCredentials()
  const openPopover = (ev) => {
    const { top, left, height, width } = ev.target.getBoundingClientRect()
    setAnchorEl(ev.currentTarget)
    setOpen({ top: top + height + 10, left: left + width })
  }
  const logOut = () => {
    setOpen(false)
    auth.signOut()
    // .then(() => {
    //   console.log('Go to login')
    //   history.push('/login')
    // })
    // .catch(() => {
    //   history.push('/login')
    // })
  }
  return [
    user && (
      <Tooltip title={<Typography>{user.email}</Typography>}>
      <ButtonBase onClick={openPopover} key='avatar' style={{ outline: 0, overflow: 'hidden' }} className="rounded-circle">
        <img className='avatar' alt='Profile' style={{ height: 44 }} src={user.photoURL || defaultPhotoUrl} />
      </ButtonBase>
      </Tooltip>
    ),
    user && (
      <Menu open={open} onClose={() => setOpen(false)} anchorEl={anchorEl} key='settings'>
        {/* {open ? <Suspense fallback={'Checking your token balance'}>
          <XyoBalance />
        </Suspense> : 'Checking your token balance'} */}
        {process.env.NODE_ENV === 'development' && <ListItem key='mock' onClick={createMockWithdrawal} button>Mock Withdrawal</ListItem>}
        <MenuItem component={Link} to='/settings' onClick={() => setOpen(false)}>
          Settings
        </MenuItem>
        <MenuItem component={Link} to='/logs' onClick={() => setOpen(false)}>
          Logs 
        </MenuItem>
        {creds && creds.router === 'full' ? <MenuItem component={Link} to='/admins' onClick={() => setOpen(false)}>
          Admins 
        </MenuItem> : null}
        <MenuItem component={Link} onClick={logOut}>
          Log Out
        </MenuItem>
      </Menu>
    )
  ]
}